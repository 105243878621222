import request from "@/utils/request";
import constantPath from "@/constant_path";

// 用户登录
export function login(data) {
  return request({
    url: constantPath.LOGIN_PATH,
    method: "post",
    data,
  });
}

// 三方系统
export function postSSO(data) {
  return request({
    url: constantPath.POSTSSO_PATH,
    method: "post",
    data,
  });
}

// 修改密码
export function changeSso(data) {
  return request({
    url: constantPath.CHANGESSO_PATH,
    method: "post",
    data,
  });
}

// 实名认证修改密码
export function rmCertSso(data) {
  return request({
    url: constantPath.RMCERTSSO_PATH,
    method: "post",
    data,
  });
}

// 第三方登录
export function thirdLogin(params) {
  return request({
    url: constantPath.THIRDLOGIN,
    params,
  });
}

export function thirdTwo(params) {
  return request({
    url: constantPath.THIRDTWO,
    params,
  });
}

//切换角色
export function toggleRole(params) {
  return request({
    url: constantPath.TOGGLE_PATH,
    method: "get",
    params,
  });
}

//验证token
export function tokenValid(params) {
  return request({
    url: constantPath.TOKEN_VALID,
    method: "get",
    params,
  });
}
