// const LOGIN_PATH = 'sso/login'
const LOGIN_PATH = "sso/v3/login"; // sso登录
const POSTSSO_PATH = "sso/3rdAuth"; // 第三方接口
const CHANGESSO_PATH = "sso/acct/chgpwd"; // 修改密码
const RMCERTSSO_PATH = "sso/v3/RMCert"; // 实名验证
const THIRDLOGIN = "nriatoauth/login"; //第三方登录 1
const THIRDTWO = "gjtauth/login"; //第三方登录 2
const TOGGLE_PATH = "sso/v3/toggleCode" //
const TOKEN_VALID = "sso/v3/tokenValid"

export default {
  LOGIN_PATH,
  POSTSSO_PATH,
  CHANGESSO_PATH,
  RMCERTSSO_PATH,
  THIRDLOGIN,
  THIRDTWO,
  TOGGLE_PATH,
  TOKEN_VALID
};
