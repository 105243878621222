import { createStore } from 'vuex'
import { getToken, setToken, removeToken, getInfo, setInfo, removeInfo, getCert, setCert, removeCert } from '@/utils/auth'

export default createStore({
  state: {
    loginInfo: getInfo() || null,
    ssoToken: null,
    certTrue: getCert() || 'false',
    currentRole: "",
    roleList: []
  },
  getters: {
    subSystem(state) {
      return state.currentRole ? state.currentRole.subSystem : [];
    },
    currentUser(state){
      return state.currentRole;
    }
  },
  mutations: {
    // token
    setSsoToken(state, ssotoken) {
      state.ssoToken = ssotoken
      setToken(ssotoken)
    },
    removeSsoToken(state) {
      state.ssoToken = null
      removeToken()
    },
    // 登录成功信息
    setLoginInfo(state, data) {
      state.loginInfo = data;

      setInfo(data)
    },
    setRoleInfo(state,data){
      state.roleList = data.userInfo.roleList;
    },
    removeLoginInfo(state) {
      state.loginInfo = null
      removeInfo()
    },
    // 是否验证
    setCertSso(state, cert) {
      state.certTrue = cert
      setCert(cert)
    },
    removeCertSso(state) {
      state.certTrue = 'false'
      removeCert()
    },
    //设置当前用户
    setCurrentRole(state, infos) {
      state.currentRole = infos;
    },
  },
  actions: {
  },
  modules: {
  }
})
