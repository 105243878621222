import axios from 'axios'
// import store from '@/store'
// import router from '@/router'
// import { removeToken } from '@/utils/auth'
// import { ElMessage } from "element-plus";

import * as ENVS from '@/env'
// const timeout = 3600

const service = axios.create({
    baseURL: ENVS.BASEURL,
    // baseURL: 'http://localhost:8080',
    timeout: ENVS.TIMEOUT
});

// 添加请求拦截器
service.interceptors.request.use(function (config) {

    // 在发送请求之前做些什么
    // if (store.getters.token) {
    //     // if (IsCheckTimeOut()) {
    //     //     store.dispatch('user/login')
    //     //     removeToken(store.getters.token)

    //     //     router.push({
    //     //         path:'/login',
    //     //         query:{
    //     //             t:IsCheckTimeOut()
    //     //         }
    //     //     })
    //     //     return ElMessage.error('身份已过期,请重新登录')
    //     //     Promise.reject(new Error('身份已过期'))
    //     // }
    //     config.headers['teach-token'] = `${store.getters.token}`
    // }

    return config
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
});

// 添加响应拦截器
service.interceptors.response.use(function (response) {
    // // 对响应数据做点什么
    // // console.log(response);

    // // token失效 回到登录页面
    // if (response.data.code === 5001) {
    //     // 解决token失效后 弹出多次提示消息的问题
    //     window.localStorage.setItem('tokenfail',true)
    //     removeToken()
    //     // ElMessage.error('身份已过期 , 请重新登录 ! ')
    //     // Promise.reject(response.data.message);
    //     setTimeout(() => {
    //         window.location.reload();
    //     }, 0)
    // }

    return response;
}, function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
});


export default service