<template>
  <!-- <div> -->
  <router-view />
  <!-- </div> -->
</template>

<script setup>
import store from "@/store";

import { removePwd } from "@/utils/auth";
import { onMounted, onUnmounted } from "vue";

function myBrowser() {
  var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
  var isOpera = userAgent.indexOf("Opera") > -1;
  if (isOpera) {
    return "Opera";
  } //判断是否Opera浏览器
  if (userAgent.indexOf("Edg") > -1) {
    return "edge";
  }
  if (userAgent.indexOf("Firefox") > -1) {
    return "FF";
  } //判断是否Firefox浏览器
  if (userAgent.indexOf("Chrome") > -1) {
    return "Chrome";
  }
  if (userAgent.indexOf("Safari") > -1) {
    return "Safari";
  } //判断是否Safari浏览器
  if (
    userAgent.indexOf("compatible") > -1 &&
    userAgent.indexOf("MSIE") > -1 &&
    !isOpera
  ) {
    return "IE";
  } //判断是否IE浏览器
}

let _beforeUnload_time = "";
let _gap_time = "";

let beforeunloadHandler = (e) => {
  _beforeUnload_time = new Date().getTime();
  //e.returnValue = '关闭提示'; 弹窗
};
let unloadHandler = (e) => {
  var mb = myBrowser();
  var second = 5;

  // 不同浏览器时间赋值
  if (mb == "edge") {
    second = 10;
  }
  if (mb == "Chrome") {
    second = 5;
  }

  _gap_time = new Date().getTime() - _beforeUnload_time;
  //判断是窗口关闭还是刷新
  if (_gap_time <= second) {
    localStorage.setItem("out", JSON.stringify(_gap_time));

    return
    // 退出登录
    store.commit("removeSsoToken");
    store.commit("removeLoginInfo");
    store.commit("removeCertSso");
    localStorage.clear();
    removePwd();
  } else {
    localStorage.setItem("upload", JSON.stringify(_gap_time));
  }
};

onMounted(() => {
  // window.addEventListener("beforeunload", (e) => beforeunloadHandler(e));
  // window.addEventListener("unload", (e) => unloadHandler(e));
});

onUnmounted(() => {
  // window.removeEventListener("beforeunload", (e) => beforeunloadHandler(e));
  // window.removeEventListener("unload", (e) => unloadHandler(e));
});
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
