import store from "@/store";
import Cookies from "js-cookie";

import { tokenValid } from "@/api/user";

const TokenKey = "sso-token";
const USERINFO = "sso-user";
const USERPWD = "sso-certpwd";
const CERTTRUE = "sso-cert-true";
const LOGININFO = "sso-logininfo";
const MAININFO = "sso-info";

// 获取token
export function getToken() {
  return Cookies.get(TokenKey);
}

export function validateToken(tokenKey) {
  //验证token
  return new Promise((resolve, reject) => {
    tokenValid({
      ssoToken: tokenKey,
    })
      .then((res) => {
        if (res.data && res.data.data && res.data.data.isValid) {
          resolve();
        } else {
          reject();
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function setToken(token) {
  return Cookies.set(TokenKey, token);
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}

// 登录成功信息
export function getInfo() {
  // Cookies.get(LOGININFO)
  const logininfo = localStorage.getItem("sso-info");

  try {
    return JSON.parse(logininfo);
  } catch (error) {
    return logininfo;
  }
  // const info = window.sessionStorage.getItem(LOGININFO)
  // return JSON.parse(info)
}

export function setInfo(data) {
  return localStorage.setItem(MAININFO, JSON.stringify(data));
  return Cookies.set(LOGININFO, JSON.stringify(data));
}

export function removeInfo() {
  return localStorage.removeItem(MAININFO);
  return Cookies.remove(LOGININFO);
}

// 记住用户名 - cookie保存的时间天数
export function getUname() {
  return Cookies.get(USERINFO);
}

export function setUname(uname, time) {
  return Cookies.set(USERINFO, uname, {
    expires: time,
  });
}

export function removeUname() {
  return Cookies.remove(USERINFO);
}

// 实名密码
export function getPwd() {
  return Cookies.get(USERPWD);
}

export function setPwd(pwd) {
  return Cookies.set(USERPWD, pwd);
}

export function removePwd() {
  return Cookies.remove(USERPWD);
}

// 是否验证
export function getCert() {
  return Cookies.get(CERTTRUE);
}

export function setCert(cert) {
  return Cookies.set(CERTTRUE, cert);
}

export function removeCert() {
  return Cookies.remove(CERTTRUE);
}

export function removeAuth() {
  store.commit("removeSsoToken");
  store.commit("removeLoginInfo");
  store.commit("removeCertSso");
  localStorage.clear();
}

// export function removeUserDate() {
//     return Cookies.remove(userDate)
// }

// export function getTimeStamp() {
//     return Cookies.get(timekey)
// }

// export function setTimeStamp() {
//     return Cookies.set(timekey, Date.now())
// }
