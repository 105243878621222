module.exports = {
    BASEURL: 'http://hyjy.hhyedu.com.cn//',
    
    TIMEOUT: 5000,  // 登录请求超时时间
    TITLE: '衡阳大数据教研平台', // 登录页系统title
    LOGOFILE: "lzhlogo.png",
    IMGURLARR: [
        "http://hyjy.hhyedu.com.cn//AD/1.jpg",
        "http://hyjy.hhyedu.com.cn//AD/2.jpg",
        "http://hyjy.hhyedu.com.cn//AD/3.jpg",
        "http://hyjy.hhyedu.com.cn//AD/4.jpg"
    ] // 登录页图片显示
}
